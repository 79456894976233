@use "~stylesheets/responsiveness";

.MLHBanner {
  display: block;
  max-width: calc(10px + 5vw);
  min-width: 60px;
  position: fixed;
  right: 25px;
  top: 0;
  width: 10%;
  z-index: 10000;

  &__image {
    width: 100%;
  }

  @include responsiveness.respond(phone) {
    visibility: hidden;
  }
}
