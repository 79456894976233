:root {
  --black: #181a1b;
  --white: #fff;
  --wedgewood: #4f728e;
  --froly: #f06c84;
  --light-aqua: #4f9c95;
  --grey: rgb(137, 167, 189);
  --dark-navy: #1a1542;
  --blue: #4366c0;
  --purple: #1a1542;
  --salmon: #f06c84;
  --burnt-sienna: #ef6f5e;
  --pink: #f06c84;

  /* variations */
  --wedgewood__dark: #{darken(#4f728e, 7%)};
  --white__off: #fdfdee;
  --grey__dark: #898989;
  --pink__light: #ffd9e0;
  --pink__medium: #ffccd5;

  /* These are for gradient */
  --wedgewood__gradient: #486988;
  --waterleaf: #a9ebdf;
  --blue__gradient: #344790;

  /* dark mode */
  --wedgewood__night: rgb(46, 67, 87);
  --waterleaf__night: rgb(18, 74, 66);
  --wedgewood__darker: rgb(151, 178, 197);

  /* anaglyph colors */
  --anaglyph-cyan: #0085ff;
  --anaglyph-red: rgba(255, 0, 0, 0.57);
  --highlight-red: #fc3b3b;
  --highlight-blue: #1a49e1;

  /* missions, milestones, and topics section */
  --frosted-glass: rgba(91, 75, 147, 0.67);
  --malibu: #7c9bff;
  --malibu__gradient: #8a9dda;
  --new-blue: rgba(138, 157, 218, 0.6);
  --heliotrope: #a687ff;
  --light-grey: #898989;
  --creame: #fdfdee;

  /* error section */
  --froly: #f37e7e;
  --froly__dark: #f06c84;
  --apricot: #f08c6c;

  --aubergine: #32060c;
  --haiti: #1b1540;
  --frosted-white: rgba(255, 255, 255, 0.28);

  /* team page */
  --dark-mahogany: #32060c;
  --midnight-blue: #1a1542;
  --light-purple: #a687ff;
  --white-blue: #e1edff;
  --light-aqua: rgba(211, 221, 255, 0.9);
}
