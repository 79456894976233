@use "~stylesheets/responsiveness";
@use "~stylesheets/typography";

@mixin footer-highlight {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  background: var(--white__off);
  box-shadow: 0 0 35px 16px rgba(255, 255, 255, 0.28);
  border: none;
  border-radius: 50%;
  padding: 1%;
}

.Footer {
  max-width: 1800px;
  margin: auto;
  padding: var(--extra-extra-large-spacer) var(--small-spacer) var(--small-spacer);

  @include responsiveness.respond(tab-port) {
    padding: 1em;
  }

  @include responsiveness.respond(phone-narrow) {
    max-width: unset;
  }

  &__images {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 var(--medium-spacer);

    @include responsiveness.respond(phone-narrow) {
      flex-direction: column;
    }
  }

  &__team {
    @include typography.title-text-secondary;
    @include footer-highlight();
    color: var(--burnt-sienna);
    height: fit-content;
    border-radius: 68.7897px;
    padding: 0.5% 1.5%;
    text-decoration: none;
  }

  &__links {
    display: flex;
    justify-content: center;

    @include responsiveness.respond(phone-narrow) {
      margin: var(--large-spacer) 0;
    }
  }

  &__link--wrapper {
    @include footer-highlight();
    height: 6vw;
    width: 6vw;
    max-height: 52px;
    max-width: 52px;
    margin: 0 1.05vw;
  }

  &__link--icon {
    width: 70%;
    height: 70%;
  }

  &__scroll {
    @include footer-highlight();
    height: fit-content;
    cursor: pointer;
  }

  &__arrow {
    fill: var(--burnt-sienna);
    width: 2em;
    min-width: 14px;
    max-width: 30px;
    transform: rotate(-90deg);
  }

  &__text {
    display: flex;
    justify-content: space-between;
    margin-top: var(--extra-large-spacer);

    @include typography.description;
    font-style: italic;
    text-shadow: 2px 2px var(--black);

    @include responsiveness.respond(tab-port) {
      flex-direction: column;
      text-align: center;
    }
  }
}
