@use "~stylesheets/typography";
@use "~stylesheets/responsiveness";

.Milestones {
  position: relative;
  margin: 0;

  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: scaleY(-1);
    z-index: 0;
  }

  &__container {
    position: relative;
    max-width: 2000px;
    min-height: 500px;
    margin: 0 auto;
  }

  &__title {
    @include typography.anaglyph-title-text;

    padding: var(--extra-extra-large-spacer) 0 var(--medium-spacer) 0;
    text-align: center;
  }

  &__boxContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1500px;
    margin: 0 auto;
  }

  &__box {
    margin: var(--medium-spacer);
    min-width: 200px;
    text-align: center;
    z-index: 3;
  }

  &__boxTop {
    @include typography.anaglyph-title-text;

    margin: 0;
    padding: 30px 40px;
    // the frosted glass effect isn't well supported in CSS so the color is hard-coded to best match the design.
    background: var(--frosted-glass);
    border-radius: 35px 35px 0 0;

    @include responsiveness.respond(tab-port) {
      padding: 20px 30px;
    }
  }

  &__boxBottom {
    @include typography.description;

    margin: 0;
    padding: 10px 20px;
    background: linear-gradient(180deg, var(--malibu) 0%, var(--malibu__gradient) 100%);
    box-shadow: 0 0 35px -3px var(--heliotrope);
    text-shadow: 1px 1px rgba(0, 0, 0, 0.15);
    border-radius: 0 0 35px 35px;
  }

  &__pencil {
    position: absolute;
    width: 450px;

    @include responsiveness.respond(phone) {
      width: 230px;
    }
  }

  &__pencil--alt {
    @extend .Milestones__pencil;
    top: 300px;
    left: 20%;
    transform: rotate(30deg);
  }

  &__shell {
    position: absolute;
    width: 300px;
    right: 50px;
    bottom: 30px;

    @include responsiveness.respond(desktop) {
      width: 200px;
    }

    @include responsiveness.respond(tab-port) {
      width: 125px;
    }
  }
}
