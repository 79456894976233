/* montserrat-regular - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("~fonts/montserrat-v15-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Montserrat Regular"), local("Montserrat-Regular"),
    url("~fonts/montserrat-v15-latin-regular.eot?#iefix") format("embedded-opentype"),
    url("~fonts/montserrat-v15-latin-regular.woff2") format("woff2"),
    url("~fonts/montserrat-v15-latin-regular.woff") format("woff"),
    url("~fonts/montserrat-v15-latin-regular.ttf") format("truetype"),
    url("~fonts/montserrat-v15-latin-regular.svg#Montserrat") format("svg"); /* Legacy iOS */
}

/* montserrat-500 - latin */
@font-face {
  font-family: "Montserrat 500";
  font-style: normal;
  font-weight: 500;
  src: url("~fonts/montserrat-v15-latin-500.eot"); /* IE9 Compat Modes */
  src: local("Montserrat Medium"), local("Montserrat-Medium"),
    url("~fonts/montserrat-v15-latin-500.eot?#iefix") format("embedded-opentype"),
    url("~fonts/montserrat-v15-latin-500.woff2") format("woff2"),
    url("~fonts/montserrat-v15-latin-500.woff") format("woff"),
    url("~fonts/montserrat-v15-latin-500.ttf") format("truetype"),
    url("~fonts/montserrat-v15-latin-500.svg#Montserrat") format("svg"); /* Legacy iOS */
}

/* montserrat-500italic - latin */
@font-face {
  font-family: "Montserrat Italic";
  font-style: italic;
  font-weight: 500;
  src: url("~fonts/montserrat-v15-latin-500italic.eot"); /* IE9 Compat Modes */
  src: local("Montserrat Medium Italic"), local("Montserrat-MediumItalic"),
    url("~fonts/montserrat-v15-latin-500italic.eot?#iefix") format("embedded-opentype"),
    url("~fonts/montserrat-v15-latin-500italic.woff2") format("woff2"),
    url("~fonts/montserrat-v15-latin-500italic.woff") format("woff"),
    url("~fonts/montserrat-v15-latin-500italic.ttf") format("truetype"),
    url("~fonts/montserrat-v15-latin-500italic.svg#Montserrat") format("svg"); /* Legacy iOS */
}

/* source-code-pro-regular - latin */
@font-face {
  font-family: "Source Code Pro";
  font-style: normal;
  font-weight: 400;
  src: url("~fonts/source-code-pro-v13-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("~fonts/source-code-pro-v13-latin-regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("~fonts/source-code-pro-v13-latin-regular.woff2") format("woff2"), /* Super Modern Browsers */
    url("~fonts/source-code-pro-v13-latin-regular.woff") format("woff"), /* Modern Browsers */
    url("~fonts/source-code-pro-v13-latin-regular.ttf") format("truetype"), /* Safari, Android, iOS */
    url("~fonts/source-code-pro-v13-latin-regular.svg#SourceCodePro") format("svg"); /* Legacy iOS */
}

/* source-code-pro-italic - latin */
@font-face {
  font-family: "Source Code Pro";
  font-style: italic;
  font-weight: 400;
  src: url("~fonts/source-code-pro-v13-latin-italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("~fonts/source-code-pro-v13-latin-italic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("~fonts/source-code-pro-v13-latin-italic.woff2") format("woff2"), /* Super Modern Browsers */
    url("~fonts/source-code-pro-v13-latin-italic.woff") format("woff"), /* Modern Browsers */
    url("~fonts/source-code-pro-v13-latin-italic.ttf") format("truetype"), /* Safari, Android, iOS */
    url("~fonts/source-code-pro-v13-latin-italic.svg#SourceCodePro") format("svg"); /* Legacy iOS */
}

/* source-code-pro-500 - latin */
@font-face {
  font-family: "Source Code Pro";
  font-style: normal;
  font-weight: 500;
  src: url("~fonts/source-code-pro-v13-latin-500.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("~fonts/source-code-pro-v13-latin-500.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("~fonts/source-code-pro-v13-latin-500.woff2") format("woff2"), /* Super Modern Browsers */
    url("~fonts/source-code-pro-v13-latin-500.woff") format("woff"), /* Modern Browsers */
    url("~fonts/source-code-pro-v13-latin-500.ttf") format("truetype"), /* Safari, Android, iOS */
    url("~fonts/source-code-pro-v13-latin-500.svg#SourceCodePro") format("svg"); /* Legacy iOS */
}

/* source-code-pro-500italic - latin */
@font-face {
  font-family: "Source Code Pro";
  font-style: italic;
  font-weight: 500;
  src: url("~fonts/source-code-pro-v13-latin-500italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("~fonts/source-code-pro-v13-latin-500italic.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("~fonts/source-code-pro-v13-latin-500italic.woff2") format("woff2"), /* Super Modern Browsers */
    url("~fonts/source-code-pro-v13-latin-500italic.woff") format("woff"), /* Modern Browsers */
    url("~fonts/source-code-pro-v13-latin-500italic.ttf") format("truetype"), /* Safari, Android, iOS */
    url("~fonts/source-code-pro-v13-latin-500italic.svg#SourceCodePro") format("svg"); /* Legacy iOS */
}
