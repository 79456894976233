@use "~stylesheets/typography";
@use "~stylesheets/responsiveness";

.Background {
  position: absolute;
  background: var(--dark-navy);
  min-height: 100%;
  overflow: hidden;
  width: 100%;
  margin-top: 26px;
  z-index: -1;

  &__children {
    z-index: 1;
    position: relative;
  }

  &__wire {
    position: absolute;
    width: 100%;
    height: 95%;
    top: 0;

    @include responsiveness.respond(big-desktop) {
      top: -5em;
    }

    @include responsiveness.respond(phone) {
      top: -10em;
    }
  }

  &__wire--variant {
    position: absolute;
    background: none;
    width: 100%;
    transform: scale(1.03);
    bottom: 0;

    @include responsiveness.respond(desktop) {
      height: 50.5%;
      width: 150%;
      margin-left: -4vw;
    }

    @include responsiveness.respond(tab-land) {
      height: 42.5%;
      width: 170%;
      margin-left: 0;
    }

    @include responsiveness.respond(tab-port) {
      height: 39.5%;
      width: 190%;
      margin-left: -15vw;
    }

    @include responsiveness.respond(phone) {
      height: 27.5%;
      width: 200%;
      margin-left: -25vw;
    }
  }

  &__gradient {
    z-index: -2;
    position: absolute;
    background: linear-gradient(
      180deg,
      var(--blue) 0%,
      var(--blue__gradient) 100%
    );
    width: 100%;
    height: calc(100% / 7 * 3);
    bottom: 0;
  }
}
