.TeamPage {
  height: fit-content;
  display: flex;
  flex-direction: column;

  &__cardsSection {
    margin-top: -1%;
    position: relative;
    background: linear-gradient(180deg, var(--dark-mahogany) 0%, var(--midnight-blue) 100%);
  }

  &__cardsContainer {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    max-width: 1500px;
    justify-content: space-around;
    align-items: flex-start;
  }
}
