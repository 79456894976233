@mixin respond($breakpoint) {
  @if ($breakpoint == smallest) {
    @media (max-width: 375px) {
      @content;
    }
  }

  @if ($breakpoint == phone-narrow) {
    @media (max-width: 35em) {
      @content;
    }
  }

  @if ($breakpoint == phone) {
    @media (max-width: 42.5em) {
      @content;
    }
  }

  @if ($breakpoint == tab-port) {
    @media (max-width: 56.25em) {
      @content;
    }
  }

  @if ($breakpoint == tab-land) {
    @media (max-width: 75em) {
      @content;
    }
  }

  @if ($breakpoint == mission) {
    @media (max-width: 85.5em) {
      @content;
    }
  }

  @if ($breakpoint == desktop) {
    @media (max-width: 112.5em) {
      @content;
    }
  }

  @if ($breakpoint == big-desktop) {
    @media (min-width: 112.5em) {
      @content;
    }
  }
}

// ============================================================================
// Responsive Fonts
// ============================================================================

///
/// Viewport sized typography with minimum and maximum values
///
/// @author Eduardo Boucas (@eduardoboucas)
///
/// @param {Number}   $responsive  - Viewport-based size
/// @param {Number}   $min         - Minimum font size (px)
/// @param {Number}   $max         - Maximum font size (px)
///                                  (optional)
/// @param {Number}   $fallback    - Fallback for viewport-
///                                  based units (optional)
///
/// @example scss - 5vw font size (with 50px fallback),
///                 minumum of 35px and maximum of 150px
///  @include responsive-font(5vw, 35px, 150px, 50px);
///
@mixin font($responsive, $min, $max: false, $fallback: false) {
  $responsive-unitless: $responsive / ($responsive - $responsive + 1);
  $dimension: if(unit($responsive) == "vh", "height", "width");
  $min-breakpoint: $min / $responsive-unitless * 100;

  @media (max-#{$dimension}: #{$min-breakpoint}) {
    font-size: $min;
  }

  @if $max {
    $max-breakpoint: $max / $responsive-unitless * 100;

    @media (min-#{$dimension}: #{$max-breakpoint}) {
      font-size: $max;
    }
  }

  @if $fallback {
    font-size: $fallback;
  }

  font-size: $responsive;
}
