@use "~stylesheets/typography";

.Button {
  transition: opacity 0.4s ease 0.27s, transform 0.2s ease, 0.8s filter ease;
  border-radius: 15px;
  padding: var(--small-spacer) var(--medium-spacer);
  border: none;
  cursor: pointer;

  &__link {
    @include typography.button-text;
    text-decoration: none;
  }

  &:hover {
    transform: translateY(-0.05vh);
  }

  &:active {
    transform: translateY(0.06vh);
  }
}
