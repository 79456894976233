@use "~stylesheets/responsiveness";
@use "~stylesheets/typography";

.Sponsors {
  position: relative;
  margin: auto;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding-bottom: calc(8% - 3vw);

  @include responsiveness.respond(tab-land) {
    transform: scale(0.77);
    max-width: 100vw;
  }

  &__logoContainer {
    display: grid;
    align-self: center;
    grid-template-columns: fit-content(300px) fit-content(300px) fit-content(300px) fit-content(300px);
    grid-template-rows: 1fr 0.6fr 0.5fr 0.1fr;
    row-gap: 3vh;
    margin: 0 auto;

    @include responsiveness.respond(phone) {
      row-gap: 2vh;
    }
  }

  &__titleContainer {
    max-width: 20em;
    margin: 0 auto;
    text-align: center;

    @include typography.description;
  }

  &__ruleLine {
    max-width: 30em;
    border: 0;
    border-bottom: calc(2px + 0.25vh) solid white;
  }

  &__title {
    //text-shadow: 2px 2px var(--black);

    @include typography.title-text;
    @include responsiveness.font($responsive: 2.2vw, $min: 18px, $max: 28px);
    line-height: calc(4em - 1.7vh);
  }

  // sponsor logos
  &__cied {
    grid-column-start: 1;
    width: calc(4vw + 3vh);
    height: fit-content;
    margin: 1vw 1vw 1vw 0;
  }

  &__blackstone {
    grid-column-start: 2;
    width: auto;
    margin: auto -8vw 2vh -2vw;
  }

  &__comcast {
    grid-column-start: 3;
    grid-column-end: 5;
    justify-self: center;
    width: calc(16vw + 16vh);
    height: fit-content;
    margin: auto 0 2vh 8vw;

    @include responsiveness.respond(phone) {
      width: calc(30vw + 2vh);
    }
  }

  // Second tier
  &__citris {
    grid-row-start: 2;
    grid-column-start: 2;
    justify-self: right;
    width: calc(12vw + 10vh);
    height: fit-content;
    margin-top: 1vw;
    margin-right: -6vw;
  }

  &__santaCruzWorks {
    grid-row-start: 2;
    grid-column-start: 3;
    width: calc(8vw + 6vh);
    height: fit-content;
    margin-left: 9vw;
    margin-right: -3vw;
  }

  // Third tier
  &__ebay {
    grid-row-start: 3;
    width: calc(8vw + 8vh);
    height: fit-content;
    margin: auto 0 auto -2.5vw;

    @include responsiveness.respond(phone) {
      width: calc(8vw + 5vh);
    }
  }

  &__southSwell {
    grid-row-start: 3;
    width: calc(12.5vw + 8vh);
    height: fit-content;
    justify-self: center;
    margin: 0 2vw 1vw 3.5vw;

    @include responsiveness.respond(phone) {
      width: calc(10vw + 6vh);
    }
  }

  &__stratovan {
    grid-row-start: 3;
    grid-column-start: 3;
    width: calc(12.5vw + 12.5vh);
    align-self: start;
    margin-left: 3.5vw;
    margin-right: -5vw;
    margin-top: -0.8vh;

    @include responsiveness.respond(phone) {
      width: calc(10vw + 10vh);
    }
  }

  &__progress {
    grid-row-start: 3;
    grid-column-start: 3;
    width: calc(12.5vw + 12.5vh);
    margin: auto -5vw -0.8vh 3.5vw;

    @include responsiveness.respond(phone) {
      width: calc(10vw + 10vh);
    }
  }

  &__fifteenSeventeen {
    grid-row-start: 3;
    grid-column-start: 4;
    width: calc(8vw + 4vh);
    height: fit-content;
    margin: auto 0 auto 6vw;
    justify-self: center;
  }

  // Fourth tier
  &__earthHacks {
    grid-row-start: 4;
    grid-column-start: 1;
    width: calc(10vw + 4vh);
    margin-left: -3vw;
    margin-top: calc(-0.5vw - 0.3vh);
  }

  &__balsamiq {
    grid-row-start: 4;
    grid-column-start: 1;
    justify-self: right;
    width: calc(6vw + 3vh);
    margin: calc(2.3vw + 1.2vh) 2vw 0 -3vw;
  }

  &__chooseSC {
    grid-row-start: 4;
    grid-column-start: 2;
    width: calc(4.25vw + 2vh);
    margin-bottom: -1vh;
    margin-left: 1.5vw;
  }

  &__wolfram {
    grid-row-start: 4;
    grid-column-start: 2;
    width: calc(10vw + 3vh);
    margin: auto 0 auto calc(7.25vw + 2vh);
    justify-self: center;

    @include responsiveness.respond(phone) {
      margin-right: 0.5vw;
    }
  }

  &__mlh {
    grid-row-start: 4;
    grid-column-start: 3;
    width: calc(5vw + 3vh);
    justify-self: start;
    align-self: center;
    margin-left: calc(0.7vw - 0.4vh);

    @include responsiveness.respond(phone) {
      margin-left: 0.5vw;
    }
  }

  &__pinnacle {
    grid-row-start: 4;
    grid-column-start: 3;
    width: calc(4vw + 1.8vh);
    margin-left: calc(7vw + 3.8vh);
    padding-top: 1.2vh;

    @include responsiveness.respond(tab-land) {
      margin-left: calc(8vw + 4.2vh);
    }
  }

  &__stickermule {
    grid-row-start: 4;
    grid-column-start: 4;
    width: calc(10vw + 5vh);
    margin-left: -3vw;
  }

  &__echoAR {
    grid-row-start: 4;
    grid-column-start: 4;
    width: calc(10vw + 3vh);
    margin: auto 0 1vh -1vw;
  }

  &__cross {
    grid-row-start: 4;
    grid-column-start: 4;
    width: calc(8vw + 4vh);
    margin: auto 0 auto calc(9vw + 7vh);

    @include responsiveness.respond(tab-land) {
      margin-left: calc(9vw + 6vh);
    }
  }
}
