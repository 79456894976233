@use "~stylesheets/responsiveness" as rsp;
@use "~stylesheets/typography" as tpy;

.TeamFooter {
  min-height: 5em;
  display: flex;
  flex-direction: column;
  margin-top: 2em;

  &__arrowContainer {
    display: flex;
    justify-content: center;
    align-items: center;

    width: calc(2vw + 15px);
    height: calc(2vw + 15px);
    background: linear-gradient(90deg, var(--salmon) 0%, var(--salmon) 100%);
    box-shadow: 0 0 22px 9px var(--pink__light);
    border-radius: 64px;
    align-self: center;
    cursor: pointer;
  }

  &__arrow {
    fill: var(--white);
    height: 60%;
    width: auto;
    transform: rotate(-90deg);
  }

  &__text {
    @include tpy.team-footer-text;
    align-self: flex-end;
    padding-top: 1em;

    @include rsp.respond(phone) {
      align-self: center;
      padding-bottom: 1em;
    }
  }
}
