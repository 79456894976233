@use "~stylesheets/responsiveness" as rsp;

.SpeakerBackground {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  @include rsp.respond(phone) {
    display: none;
  }

  @include rsp.respond(tab-port) {
    display: none;
  }

  @include rsp.respond(tab-land) {
    display: none;
  }

  &__backdrop {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
  }

  &__upLeftConstellation {
    position: absolute;
    z-index: -1;
    top: 8%;
    left: 9%;
    width: auto;
    height: 25%;
  }

  &__botLeftConstellation {
    position: absolute;
    z-index: -1;
    left: 4%;
    bottom: 8%;
    width: auto;
    height: 40%;
  }

  &__topRightConstellation {
    position: absolute;
    z-index: -1;
    top: 10%;
    right: 8%;
    width: auto;
    height: 23%;
  }

  &__botRightConstellation {
    position: absolute;
    bottom: 18%;
    right: 6%;
    z-index: -1;
    width: auto;
    height: 25%;
  }

  &__leftCircle {
    position: absolute;
    width: auto;
    height: 80%;
    left: 8%;
    z-index: -1;
  }

  &__rightCircle {
    position: absolute;
    width: auto;
    height: 40%;
    right: 9%;
    bottom: 1%;
    z-index: -1;
  }
}
