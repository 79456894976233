@use "~components/Button/Button";
@use "~stylesheets/typography";
@use "~stylesheets/responsiveness";

.Navbar {
  display: flex;
  align-items: center;
  padding: 0 var(--extra-large-spacer);
  margin-bottom: -54px;

  background: var(--white);
  filter: drop-shadow(0 10px 15px rgba(0, 0, 0, 0.3));
  border-radius: 0  0 25px 25px;

  position: relative;
  z-index: 1;

  &__title {
    @include typography.title-text;
    color: var(--froly);
    text-align: center;
    margin-right: var(--extra-large-spacer);
    text-decoration: none;

    @include responsiveness.font($responsive: 3.8vw, $min: 21px, $max: 35px);
  }

  &__buttonContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include responsiveness.respond(tab-land) {
      display: none;
    }
  }

  &__button {
    @extend .Button;
    background: var(--froly);
    margin: var(--small-spacer) var(--large-spacer) var(--small-spacer) 0;
  }

  &__menuItems--primary {
    display: none;

    @include responsiveness.respond(tab-land) {
      display: inherit;
    }
  }

  &__menuItems--secondary {
    display: inherit;

    @include responsiveness.respond(tab-land) {
      display: none;
    }
  }

  &__link {
    color: var(--froly);
    text-decoration: none;
  }
}
