@use "~stylesheets/typography";
@use "~stylesheets/responsiveness";

.FAQ {
  position: relative;
  display: flex;
  justify-content: center;
  z-index: 0;
  overflow: hidden;
  height: auto;
  max-width: 1700px;
  min-height: fit-content;
  padding-top: 5%;
  padding-bottom: 7%;
  margin: 0 auto;

  @mixin section {
    flex: 1;
    width: calc(100% / 3);
    padding-bottom: 1em;

    @include responsiveness.respond(tab-port) {
      width: 100%;
    }
  }

  &__whiteRect {
    max-width: 1800px;
    position: relative;
    width: 80%;
    height: fit-content;
    box-shadow: 0 0 20px 11px rgba(89, 93, 130, 0.5);
    border-radius: 30px;
    z-index: 1;
  }

  &__box--top {
    @include typography.alt-title-text-medium;

    background: var(--white__off);
    border-radius: 30px 30px 0 0;
    color: var(--salmon);
    padding-top: 0.5em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-bottom: calc(1vh + 0.5vw);

    &::after {
      content: "Frequently Asked Questions";
    }

    @include responsiveness.respond(tab-port) {
      padding-top: 2vh;
      padding-left: 4vw;
      padding-right: 4vw;
    }

    @include responsiveness.respond(smallest) {
      padding-top: calc(0.1em + 0.85vh - 0.1vw);
      padding-left: 5vw;

      &::after {
        content: "FAQ";
      }
    }
  }

  &__box--bottom {
    display: flex;
    height: fit-content;
    border-radius: 0 0 30px 30px;
    flex-direction: row;

    @include responsiveness.respond(tab-port) {
      flex-direction: column;
    }
  }

  &__text--question {
    @include typography.source-description-purple;

    font-style: normal;
    font-weight: bold;
    color: var(--salmon);
    margin: 1em;
  }

  &__text--answer {
    @include typography.source-description-purple;

    font-weight: 400;
    font-style: normal;
    padding-left: 1em;
    padding-right: 1em;
  }

  &__pinkRect--left {
    @include section;

    background: var(--pink__light);
    border-radius: 0 0 0 30px;

    @include responsiveness.respond(tab-port) {
      border-radius: 0 0 0 0;
    }
  }

  &__pinkRect--middle {
    @include section;

    background: var(--pink__medium);

    @include responsiveness.respond(tab-port) {
      border-radius: 0 0 0 0;
    }
  }

  &__pinkRect--right {
    @include section;

    border-radius: 0 0 30px 0;
    background: var(--pink__light);

    @include responsiveness.respond(tab-port) {
      border-radius: 0 0 30px 30px;
    }
  }
}
